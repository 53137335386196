import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

export default function AuthProtect({ children }) {

  const { isLoading, isAuthenticated, roles } = useAuth();
  const whitelist = [PATH_DASHBOARD.root, PATH_DASHBOARD.root + "/app", PATH_AUTH.login, PATH_PAGE.page404, PATH_PAGE.page500, PATH_DASHBOARD.user, PATH_DASHBOARD.user.profile, PATH_DASHBOARD.user.account]
  if (roles) {
    for (const rol of roles) {
      whitelist.push(PATH_DASHBOARD.root + rol.ROUTE)
    }
  }

  const location = useLocation();
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }
  //+1 829-341-6111
  if (roles !== undefined) {
    if (roles.length > 0) {
      if (whitelist.indexOf(location.pathname) < 0) {
        return <Redirect to={PATH_PAGE.page404} />;
      }
    }
  }




  return <>{children}</>;
}
