

const configApp = {
  baseUrl: process.env.REACT_APP_URL,
  baseUrl2: process.env.REACT_APP_URL2,
  nameApp: process.env.REACT_APP_NAME,
  api_key: process.env.REACT_APP_API,
  moneda: "RD$",
};
export default configApp;

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL,
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
