import { useDispatch, useSelector } from 'react-redux';
// redux
import { openModal, closeModal, changeContent, changeTitle, changeType, changeButtons, changeMaster } from '../redux/slices/modelDialog'
// ----------------------------------------------------------------------

export default function useModal() {
    // JWT Auth
    const dispatch = useDispatch();
    const { stateOpen, dialogTitle, dialogContentText, stateType, stateButtons, master } = useSelector(
        (state) => state.modal
    );
    // JWT Auth
    return {
        stateOpen,
        dialogTitle,
        dialogContentText,
        stateType,
        stateButtons,
        master,
        //funtions returned from the hook
        openModal: ({ title, content, type, buttons, master = false }) => {
            dispatch(closeModal());
            dispatch(changeType(type));
            dispatch(changeTitle(title));
            dispatch(changeContent(content));
            dispatch(changeButtons(buttons));
            dispatch(changeMaster(master))
            dispatch(openModal());
        },
        closeModal: () => { dispatch(closeModal()) },
    };
}
