import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import Label from "../../components/Label";
// material
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  experimentalStyled as styled,
  useTheme,
} from "@material-ui/core/styles";
import {
  Box,
  Link,
  List,
  Drawer,
  IconButton,
  Typography,
  ListSubheader,
} from "@material-ui/core";
// hooks
import useAuth from "../../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
import SvgIconStyle from "../../components/SvgIconStyle";
// components
import Logo from "../../components/Logo";
import MyAvatar from "../../components/MyAvatar";
import Scrollbar from "../../components/Scrollbar";
//
import SidebarItem from "./SidebarItem";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />,
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

function crearSubHeader(title, iconl, href, items = null) {
  if (items === null) {
    return { title, icon: getIcon(iconl), href };
  }
  return { title, items, icon: getIcon(iconl), href };
}
function createItems(title, href, items = null) {
  return { title, href, items };
}
function createItem(title, href) {
  return { title, href };
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user, roles } = useAuth();
  var MenuLinks = [];
  let object;
  var a = -1;
  if (roles) {
    roles.forEach((rol) => {
      if (rol.CODIGO.length === 1) {
        object = {};
        MenuLinks.push(object);
        object.subheader = rol.NOMBRE;
        object.items = [];
      } else if (rol.CODIGO.length === 4) {
        object.items.push(
          crearSubHeader(rol.NOMBRE, rol.ICON, PATH_DASHBOARD.root + rol.ROUTE)
        );
      } else if (rol.CODIGO.length === 7) {
        a = object.items.length - 1;
        if (object.items[a].items === undefined) {
          object.items[a].items = [];
        }
        object.items[a].items.push(
          createItems(rol.NOMBRE, PATH_DASHBOARD.root + rol.ROUTE, null)
        );
      } else if (rol.CODIGO.length === 10) {
        let b = object.items[a].items.length - 1;
        if (object.items[a].items[b].items === null) {
          object.items[a].items[b].items = [];
        }
        object.items[a].items[b].items.push(
          createItem(rol.NOMBRE, PATH_DASHBOARD.root + rol.ROUTE)
        );
      }
    });
  }

  let counter = 0;
  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2, py: 2 }}>
        <IconButton onClick={onCloseSidebar}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.root}>
        <AccountStyle>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Label>
              De: {user.ISADMIN ? "Administrador Global" : user.NOMBRE_SUCURSAL}
            </Label>
            <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
              {user.NOMBRE}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {user.GrupoName}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      {MenuLinks.map((list) => {
        counter++;
        return (
          <List
            disablePadding
            key={counter}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: "text.primary",
                  typography: "overline",
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              pathname,
            })}
          </List>
        );
      })}

      <Box mb={10} />
    </Scrollbar>
  );

  return (
    <Drawer
      open={isOpenSidebar}
      variant="persistent"
      PaperProps={{
        sx: { width: DRAWER_WIDTH, bgcolor: "background.default" },
      }}
    >
      {renderContent}
    </Drawer>
  );
}
