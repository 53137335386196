// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DOCS = "/docs";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  operaciones: {
    root: path(ROOTS_DASHBOARD, "/operaciones"),
    ventas: {
      root: path(ROOTS_DASHBOARD, "/operaciones/venta"),
      cuadre_manguera: path(
        ROOTS_DASHBOARD,
        "/operaciones/venta/cuadre_manguera"
      ),
      gNCF: path(ROOTS_DASHBOARD, "/operaciones/venta/g_ncf"),
      unicoIngreso: path(ROOTS_DASHBOARD, "/operaciones/venta/unicoI"),
    },
  },
  configuracion: {
    usuarios: path(ROOTS_DASHBOARD, "/configuracion/usuarios"),
    grupos: path(ROOTS_DASHBOARD, "/configuracion/grupos"),
    company: path(ROOTS_DASHBOARD, "/configuracion/company"),
  },
  reportes: {
    root: path(ROOTS_DASHBOARD, "/reportes"),
    punto_venta: {
      root: path(ROOTS_DASHBOARD, "/reportes/pv"),
      comprobante: path(ROOTS_DASHBOARD, "/reportes/pv/comprobante"),
      generalReport: path(ROOTS_DASHBOARD, "/reportes/pv/generalReport"),
    },
  },
  maestros: {
    suplidor: {
      root: path(ROOTS_DASHBOARD, "/suplidores"),
      suplidor: path(ROOTS_DASHBOARD, "/suplidores/suplidor"),
      tiposuplidor: path(ROOTS_DASHBOARD, "/suplidores/tiposuplidor"),
    },
    inventario: {
      root: path(ROOTS_DASHBOARD, "/invetario"),
      marca: path(ROOTS_DASHBOARD, "/invetario/marcas"),
      almacen: path(ROOTS_DASHBOARD, "/invetario/almacen"),
      tipoArticulo: path(ROOTS_DASHBOARD, "/invetario/tipoArticulo"),
      tipoSubArticulo: path(ROOTS_DASHBOARD, "/invetario/tipoSubArticulo"),
      articulos: path(ROOTS_DASHBOARD, "/inventario/articulo"),
    },
    vendedores: {
      root: path(ROOTS_DASHBOARD, "/vendedores"),
      registro: path(ROOTS_DASHBOARD, "/vendedores/registro"),
      tipos: path(ROOTS_DASHBOARD, "/vendedores/tipos"),
    },
    clientes: {
      root: path(ROOTS_DASHBOARD, "/clientes"),
      registro: path(ROOTS_DASHBOARD, "/clientes/registro"),
      tipos: path(ROOTS_DASHBOARD, "/clientes/tipos"),
    },
    otros_m: {
      root: path(ROOTS_DASHBOARD, "/otros_m"),
      sucursales: path(ROOTS_DASHBOARD, "/otros_m/sucursales"),
      bancos: path(ROOTS_DASHBOARD, "/otros_m/bancos"),
      ciudad: path(ROOTS_DASHBOARD, "/otros_m/ciudad"),
      impuesto: path(ROOTS_DASHBOARD, "/otros_m/impuestos"),
      zona: path(ROOTS_DASHBOARD, "/otros_m/zonas"),
      pais: path(ROOTS_DASHBOARD, "/otros_m/pais"),
      comprobantes_fiscales: path(
        ROOTS_DASHBOARD,
        "/otros_m/comprobantesFiscales"
      ),
      manguera: path(ROOTS_DASHBOARD, "/otros_m/manguera"),
    },
  },
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/card"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, "/introduction"),
  started: path(ROOTS_DOCS, "/getting-started"),
  package: path(ROOTS_DOCS, "/package"),
  // Theme UI
  color: path(ROOTS_DOCS, "/color"),
  typography: path(ROOTS_DOCS, "/typography"),
  icon: path(ROOTS_DOCS, "/icon"),
  shadows: path(ROOTS_DOCS, "/shadows"),
  components: path(ROOTS_DOCS, "/components"),
  tips: path(ROOTS_DOCS, "/tips"),
  // Development
  routing: path(ROOTS_DOCS, "/routing"),
  environmentVariables: path(ROOTS_DOCS, "/environment-variables"),
  stateManagement: path(ROOTS_DOCS, "/state-management"),
  apiCalls: path(ROOTS_DOCS, "/api-calls"),
  analytics: path(ROOTS_DOCS, "/analytics"),
  authentication: path(ROOTS_DOCS, "/authentication"),
  multiLanguage: path(ROOTS_DOCS, "/multi-language"),
  lazyload: path(ROOTS_DOCS, "/lazyload-image"),
  formHelper: path(ROOTS_DOCS, "/form-helper"),
  // Changelog
  support: path(ROOTS_DOCS, "/support"),
  changelog: path(ROOTS_DOCS, "/changelog"),
};
