import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------
const initialState = {
    dialogTitle: 'Mensaje',
    dialogContentText: 'Hola Como estas',
    stateOpen: false,
    stateType: "error", // error,warning,success,info,question
    stateButtons: null,
    master: false,
};
const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state) {
            state.stateOpen = true
        },
        closeModal(state) {
            state.stateOpen = false;
            state.stateButtons = null;
        },
        changeTitle(state, action) {
            state.dialogTitle = action.payload;
        },
        changeContent(state, action) {
            state.dialogContentText = action.payload;
        },
        changeType(state, action) {
            state.stateType = action.payload;
        },
        changeButtons(state, action) {
            state.stateButtons = action.payload;
        },
        changeMaster(state, action) {
            state.master = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
// Actions
export const { openModal, closeModal, changeTitle, changeContent, changeType, changeButtons, changeMaster } = slice.actions;
