import { lazy } from "react";
import { Redirect } from "react-router-dom";
// guards
import AuthGuard from "../guards/AuthGuard";
// layouts
import DashboardLayout from "../layouts/dashboard";
//
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // MAESTROS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.app} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.app,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.profile,
      component: () => <Redirect to={PATH_DASHBOARD.user.account} />,
    },
    //Maestros //Suplidores
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.suplidor.suplidor,
      component: lazy(() =>
        import("../views/pages/maestros/suplidores/suplidor")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.suplidor.tiposuplidor,
      component: lazy(() =>
        import("../views/pages/maestros/tipo_suplidores/tipo_suplidores")
      ),
    },
    //Maestros/Inventario /Marcas
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.inventario.articulos,
      component: lazy(() =>
        import("../views/pages/maestros/articulos/articulo")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.inventario.tipoArticulo,
      component: lazy(() =>
        import("../views/pages/maestros/tipo_articulos/tipo_articulo")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.inventario.tipoSubArticulo,
      component: lazy(() =>
        import("../views/pages/maestros/tipoSubA/tiposubarticulo")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.inventario.marca,
      component: lazy(() => import("../views/pages/maestros/marca.page")),
    },
    //Maestros/Inventario /Almacen
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.inventario.almacen,
      component: lazy(() =>
        import("../views/pages/maestros/almacenes/almacen")
      ),
    },
    //Maestros/Clientes
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.vendedores.registro,
      component: lazy(() => import("../views/pages/maestros/vendedor.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.vendedores.tipos,
      component: lazy(() =>
        import("../views/pages/maestros/tipo_vendedores/tipoVendedor.page")
      ),
    },
    //Maestros/Clientes
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.clientes.registro,
      component: lazy(() =>
        import("../views/pages/maestros/cliente/clientes.page")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.clientes.tipos,
      component: lazy(() =>
        import("../views/pages/maestros/tipo_cliente.page")
      ),
    },
    // Maestros/otros maestos
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.bancos,
      component: lazy(() => import("../views/pages/maestros/bancos.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.ciudad,
      component: lazy(() => import("../views/pages/maestros/ciudad.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.impuesto,
      component: lazy(() => import("../views/pages/maestros/impuestos.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.sucursales,
      component: lazy(() => import("../views/pages/maestros/sucursales")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.zona,
      component: lazy(() => import("../views/pages/maestros/zona.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.pais,
      component: lazy(() => import("../views/pages/maestros/pais.page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.manguera,
      component: lazy(() => import("../views/pages/manguera/manguera")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.operaciones.ventas.gNCF,
      component: lazy(() =>
        import("../views/pages/Operaciones/generarNCF/index")
      ),
    },
    //OPERACIONES
    //OPERACIONES VENTAS
    {
      exact: true,
      path: PATH_DASHBOARD.operaciones.ventas.cuadre_manguera,
      component: lazy(() =>
        import("../views/pages/cuadreManguera/cuadreManguera")
      ),
    },
    //procedimientos
    {
      exact: true,
      path: PATH_DASHBOARD.user.account,
      component: lazy(() => import("../views/UserAccount")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />,
    },
    {
      exact: true,
      path: PATH_DASHBOARD.maestros.otros_m.comprobantes_fiscales,
      component: lazy(() =>
        import("../views/pages/maestros/comprobantes_fiscales")
      ),
    },
    // Configuracion
    {
      exact: true,
      path: PATH_DASHBOARD.configuracion.grupos,
      component: lazy(() => import("../views/pages/grupos/grupos_page")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.configuracion.usuarios,
      component: lazy(() => import("../views/pages/users/user")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.configuracion.company,
      component: lazy(() => import("../views/pages/company.js")),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.reportes.punto_venta.comprobante,
      component: lazy(() => import("../views/pages/Report/comprobantes")),
    },
    // ----------------------------------------------------------------------

    // Reportes
    {
      exact: true,
      path: PATH_DASHBOARD.operaciones.ventas.unicoIngreso,
      component: lazy(() =>
        import("../views/pages/Operaciones/unicoIngreso/index")
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.reportes.punto_venta.generalReport,
      component: lazy(() => import("../views/pages/Report/reportGeneralNCF")),
    },
    //------------------------------------------------

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default DashboardRoutes;

//TODO: verificar siempre los nombres de rutas
