import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
//Iconos
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import ReportTwoToneIcon from "@material-ui/icons/ReportTwoTone";
import ReportProblemTwoToneIcon from "@material-ui/icons/ReportProblemTwoTone";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import HelpTwoToneIcon from "@material-ui/icons/HelpTwoTone";
//hooks
import useModal from "../../hooks/useModal";
//funtions out from component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal() {
  const {
    stateOpen,
    closeModal,
    dialogTitle,
    dialogContentText,
    stateType,
    stateButtons,
    master
  } = useModal();
  let color;
  let icon;
  switch (stateType) {
    case "error":
      color = "#c44848";
      icon = <ReportTwoToneIcon fontSize="large" />;
      break;
    case "warning":
      color = "#c18c47";
      icon = <ReportProblemTwoToneIcon fontSize="large" />;
      break;
    case "success":
      color = "#349157";
      icon = <CheckCircleTwoToneIcon fontSize="large" />;
      break;
    case "info":
      color = "#3379a5";
      icon = <InfoTwoToneIcon fontSize="large" />;
      break;
    case "help":
      color = "#4768c1";
      icon = <HelpTwoToneIcon fontSize="large" />;
    default:
      color = "#4768c1";
  }

  return (
    <Dialog
      open={stateOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={master ? () => { } : closeModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        style={{
          width: "100%",
          backgroundColor: color,
          height: "70px",
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        {icon}
        <DialogTitle sx={{ pb: 3, color: "white" }}>{dialogTitle}</DialogTitle>
      </div>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{stateButtons}</DialogActions>
    </Dialog>
  );
}
