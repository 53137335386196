
// i18n
import "./locales/i18n";

// highlight
import "./utils/highlight";

// scroll bar
import "simplebar/src/simplebar.css";

// lightbox
import "react-image-lightbox/style.css";

// editor


import ReactDOM from "react-dom";
import App from "./App";

// ----------------------------------------------------------------------
ReactDOM.render(<App />, document.getElementById("root"));


